<div class="container" [class.menu-open]="isMenuOpen()">
  @if (menuOptions().length !== 0) {
    <button class="menu-button" (click)="isMenuOpen.set(!isMenuOpen())" (blur)="menuButtonBlur($event)" testid="menu-button" data-cy="menu-button">
      <enzo-icon icon="menu" color="white" />
    </button>
    <div #flyout class="flyout">
      @for (optionGroup of menuOptions(); track optionGroup) {
        @for (option of optionGroup; let last = $last; track option) {
          <button
            [class.has-divider]="last"
            [attr.data-cy]="option.dataAttributes?.cy"
            [disabled]="option.isActive"
            (click)="menuButtonClick($event, option)"
            (blur)="menuButtonBlur($event)"
            testid="menu-item">
            <enzo-text [icon]="option.icon" iconSize="small" iconSpacing="4" [kind]="option.isActive ? 'body-bold' : 'body'">
              {{ option.label }}
            </enzo-text>
            @if (option.badge) {
              <enzo-badge [color]="option.badge.color" [label]="option.badge.label" />
            }
          </button>
        }
      }
    </div>
  }
  <a [href]="logoLink()" (click)="router.navigateByUrl(logoLink())" testid="logo">
    <enzo-logo [options]="{ kind: 'primary', size: '56' }" />
  </a>
  @if (canChangeUserRole()) {
    <button
      class="toggle-button"
      [class.active]="selectedUserRole() === EUserRole.Buyers"
      (click)="selectedUserRole.set(EUserRole.Buyers)"
      testid="buyer-button">
      <enzo-icon icon="buyer" size="small" />
      <enzo-text kind="emphasis-label">{{ 'buyer-seller-integration.buyer' | translate }}</enzo-text>
    </button>
    <button
      class="toggle-button"
      [class.active]="selectedUserRole() === EUserRole.Sellers"
      (click)="selectedUserRole.set(EUserRole.Sellers)"
      testid="seller-button">
      <enzo-icon icon="seller" size="small" />
      <enzo-text kind="emphasis-label">{{ 'buyer-seller-integration.seller' | translate }}</enzo-text>
    </button>
  }
  <div class="notifications">
    <ng-content select="[notifications]" />
  </div>
  <enzo-language-select />
</div>

@if (accountBanner()) {
  <div class="account-banner">
    <enzo-text class="title" kind="emphasis-label" icon="warning" iconSize="small">
      {{ accountBanner().title }}
    </enzo-text>
    <enzo-text class="description" kind="label">
      {{ accountBanner().description }}
    </enzo-text>
    @if (accountBanner().action) {
      <button class="button" (click)="accountBanner().action.handler()">
        <enzo-text kind="label">
          {{ accountBanner().action.label }}
        </enzo-text>
      </button>
    }
  </div>
}
