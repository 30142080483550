<button class="trigger" (click)="toggleOpen()" testid="toggle-button">
  @if (loading()) {
    <enzo-loader color="light" size="large" testid="loading-spinner" />
  } @else {
    <enzo-flag [country]="countryOfCurrentLanguage()" testid="selected-flag" />
    <enzo-text kind="body-bold" testid="selected-language">
      {{ currentLanguage() }}
    </enzo-text>
  }
</button>
<div class="flyout">
  <div class="scrollContainer">
    @for (language of supportedLanguages(); track language) {
      <button [disabled]="language === currentLanguage()" (click)="selectLanguage(language)" testid="option-button">
        <enzo-flag [country]="getCountryCodeForLanguage(language)" />
        <enzo-text [kind]="language === currentLanguage() ? 'body-bold' : 'body'">{{ language }}</enzo-text>
      </button>
    }
  </div>
</div>
