import { inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { IAuctionFilter } from '@caronsale/cos-models';
import { MAX_PAGE_SIZE } from '@cosBuyer/auctions/partials/services/auction-filter/auction-filter.service';
import { BidPlacedProperties } from '@cosCoreServices/product-analytics/amplitude/ampli';
import { FEATURE_FLAGS_DEFAULTS, ProductAnalyticsService } from '@cosCoreServices/product-analytics/product-analytics.service';
import { createStore, withProps, select } from '@ngneat/elf';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { map } from 'rxjs';

const LOCAL_STORAGE_PREFERENCE = 'LIST_TYPE_PREFERENCE';

type State = {
  selectedValue: EListType;
};

export enum EListType {
  DEFAULT = 'default',
  POWER = 'power',
}

export function mapEListTypeToAmplitudeKeys(key: EListType): BidPlacedProperties['List type'] {
  return {
    [EListType.DEFAULT]: 'Default',
    [EListType.POWER]: 'Power',
  }[key] as BidPlacedProperties['List type'];
}

const DEFAULT_ITEMS_PER_PAGE: IAuctionFilter['limit'] = 20;

@Injectable({
  providedIn: 'root',
})
export class ListTypeService {
  // dependencies
  private analyticsService = inject(ProductAnalyticsService);
  private store = createStore({ name: 'listType' }, withProps<State>({ selectedValue: EListType.DEFAULT }));
  private powerListItemsPerPage = toSignal(
    this.analyticsService.getFeatureFlag('mpw-power-list-items-per-page', FEATURE_FLAGS_DEFAULTS['mpw-power-list-items-per-page']).pipe(
      map(({ payload }) => (typeof payload === 'number' ? payload : DEFAULT_ITEMS_PER_PAGE)),
      map(itemsPerPage => Math.min(itemsPerPage, MAX_PAGE_SIZE)),
    ),
    { initialValue: DEFAULT_ITEMS_PER_PAGE },
  );
  public constructor() {
    persistState(this.store, {
      key: LOCAL_STORAGE_PREFERENCE,
      storage: localStorageStrategy,
    });
  }
  // selectors
  public selectListType = this.store.pipe(select(({ selectedValue }) => selectedValue));

  public setListType(value: EListType): void {
    this.store.update(state => ({ ...state, selectedValue: value }));
  }

  public getPaginationForCurrentListType(): Pick<IAuctionFilter, 'limit' | 'offset'> {
    const selectedValue = this.store.getValue().selectedValue;
    const limit = selectedValue === EListType.DEFAULT ? DEFAULT_ITEMS_PER_PAGE : this.powerListItemsPerPage();

    return { limit, offset: 0 };
  }
}
