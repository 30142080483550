import { IAppEnvironment } from '@cosCoreEnvironments/IAppEnvironment';
import { ELanguageCode } from '@caronsale/cos-models';

export const commonEnvironmentSettings: Pick<IAppEnvironment, 'phraseConfig' | 'translationUrls'> = {
  phraseConfig: {
    phraseProjectId: '0f536a58e5500bf2e87f3a0d5115d961',
    phraseAccessToken: 'b41bff11461079659104134c344bba376f226923177b5cb6cb78b9779429c421',
    phraseEndpointUrl: 'https://api.phrase.com/v2/projects/',
    locallyStoredDefaultLanguage: 'de',
    languageFallback: 'en',
    hiddenLanguagesPhraseVariableName: 'hiddenLanguages',
  },

  translationUrls: {
    [ELanguageCode.BG]: 'https://res.cloudinary.com/castle-tech-gmbh/raw/upload/i18n/frontend-web/bg.json',
    [ELanguageCode.CS]: 'https://res.cloudinary.com/castle-tech-gmbh/raw/upload/i18n/frontend-web/cs.json',
    [ELanguageCode.DA]: 'https://res.cloudinary.com/castle-tech-gmbh/raw/upload/i18n/frontend-web/da.json',
    [ELanguageCode.EN]: 'https://res.cloudinary.com/castle-tech-gmbh/raw/upload/i18n/frontend-web/en.json',
    [ELanguageCode.ES]: 'https://res.cloudinary.com/castle-tech-gmbh/raw/upload/i18n/frontend-web/es.json',
    [ELanguageCode.FR]: 'https://res.cloudinary.com/castle-tech-gmbh/raw/upload/i18n/frontend-web/fr.json',
    [ELanguageCode.HU]: 'https://res.cloudinary.com/castle-tech-gmbh/raw/upload/i18n/frontend-web/hu.json',
    [ELanguageCode.IT]: 'https://res.cloudinary.com/castle-tech-gmbh/raw/upload/i18n/frontend-web/it.json',
    [ELanguageCode.LT]: 'https://res.cloudinary.com/castle-tech-gmbh/raw/upload/i18n/frontend-web/lt.json',
    [ELanguageCode.NL]: 'https://res.cloudinary.com/castle-tech-gmbh/raw/upload/i18n/frontend-web/nl.json',
    [ELanguageCode.PL]: 'https://res.cloudinary.com/castle-tech-gmbh/raw/upload/i18n/frontend-web/pl.json',
    [ELanguageCode.PT]: 'https://res.cloudinary.com/castle-tech-gmbh/raw/upload/i18n/frontend-web/pt.json',
    [ELanguageCode.RO]: 'https://res.cloudinary.com/castle-tech-gmbh/raw/upload/i18n/frontend-web/ro.json',
    [ELanguageCode.SK]: 'https://res.cloudinary.com/castle-tech-gmbh/raw/upload/i18n/frontend-web/sk.json',
    [ELanguageCode.SV]: 'https://res.cloudinary.com/castle-tech-gmbh/raw/upload/i18n/frontend-web/sv.json',
    [ELanguageCode.TR]: 'https://res.cloudinary.com/castle-tech-gmbh/raw/upload/i18n/frontend-web/tr.json',
  },
};
