import { Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { ESellerBusinessType, IAuctionFilter } from '@caronsale/cos-models';
import { createStore, select, Store, StoreDef, withProps } from '@ngneat/elf';
import { map, Observable, pairwise } from 'rxjs';
import { ListTypeService } from '@cosBuyer/auctions/partials/services/list-type/list-type.service';
import { AuctionFilterUtils, EPowerUnit } from '@cosBuyer/partials/services/auction-filter-utils/auction-filter-utils';
import { BuyerAuctionSearchService } from '@cosBuyer/partials/services/auction-search/auction-search.service';
export interface FilterState {
  filter: IAuctionFilter;
  powerUnit: EPowerUnit;
}

const PRIVATE_AUCTIONS_FILTER: IAuctionFilter = {
  sellerAccounts: {
    sellerTypes: [ESellerBusinessType.PRIVATE],
  },
};

export const MAX_PAGE_SIZE = 50;

@Injectable()
export class AuctionFilterService<T extends FilterState = FilterState> {
  protected store: Store<StoreDef<T>>;
  public selectFilter: Observable<IAuctionFilter>;
  public selectFilterWithoutPagination: Observable<IAuctionFilter>;
  public selectPagination: Observable<{ limit: number; offset: number }>;
  public selectPowerUnit: Observable<EPowerUnit>;
  public selectQuickSearch: Observable<string>;
  public searchResultsCount = signal(0);
  public addToSearchHistory = false;

  public constructor(
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected buyerAuctionSearchService: BuyerAuctionSearchService,
    protected listTypeService: ListTypeService,
  ) {
    this.createStore();
    this.initListTypeListener();
    this.createStoreSelectors();
    this.initSubscriptions();
  }

  private createStore() {
    this.store = createStore({ name: 'filters' }, withProps(this.getStoreInitialState()));
  }

  private initListTypeListener() {
    this.listTypeService.selectListType.pipe(pairwise(), takeUntilDestroyed()).subscribe(() => {
      const filter = this.store.getValue().filter;
      const paginationForListType = this.listTypeService.getPaginationForCurrentListType();

      if (filter.limit !== paginationForListType.limit) {
        this.updatePagination(paginationForListType);
      }
    });
  }

  private isFilteringByPrivateSeller() {
    return this.store.getValue().filter.sellerAccounts?.sellerTypes?.some(type => type === ESellerBusinessType.PRIVATE);
  }

  private getResetState() {
    return this.isFilteringByPrivateSeller() ? PRIVATE_AUCTIONS_FILTER : {};
  }

  protected createStoreSelectors() {
    this.selectFilter = this.store.pipe(select(({ filter }) => ({ ...filter })));
    this.selectFilterWithoutPagination = this.selectFilter.pipe(map(filter => this.buyerAuctionSearchService.removeLimitAndOffset(filter)));
    this.selectPagination = this.selectFilter.pipe(map(({ limit, offset }) => ({ limit, offset })));
    this.selectPowerUnit = this.store.pipe(select(({ powerUnit }) => powerUnit));
    this.selectQuickSearch = this.selectFilter.pipe(map(({ search }) => search));
  }

  protected getStoreInitialState(): T {
    return {
      powerUnit: AuctionFilterUtils.getPowerPreferenceFromLocalStorage(),
      filter: this.listTypeService.getPaginationForCurrentListType(),
    } as T;
  }

  protected initSubscriptions() {
    this.activatedRoute.queryParams.pipe(takeUntilDestroyed()).subscribe(params => {
      let newFilter = { ...params };
      if (!this.isPaginationValid(newFilter)) {
        newFilter = {
          ...newFilter,
          ...this.listTypeService.getPaginationForCurrentListType(),
        };
      }
      newFilter = this.buyerAuctionSearchService.getAuctionFilterFromQueryParams(newFilter);

      newFilter.vehicleSearchQuery = AuctionFilterUtils.convertMakesInVehicleFilter(newFilter.vehicleSearchQuery);
      newFilter = this.buyerAuctionSearchService.removeEmptyValuesFromFilter(newFilter);

      this.store.update(state => ({ ...state, filter: newFilter }));
    });
  }

  protected navigateWithQueryParams(params: Partial<T>) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.transformStateIntoQueryParams(params),
      replaceUrl: true,
    });
  }

  protected createUrlFilter(filter: IAuctionFilter) {
    let newFilter = this.buyerAuctionSearchService.removeEmptyValuesFromFilter(filter);

    if (this.areFiltersDifferent(newFilter, this.store.getValue().filter) || !this.isPaginationValid(newFilter)) {
      newFilter = {
        ...newFilter,
        ...this.listTypeService.getPaginationForCurrentListType(),
      };
    }

    return AuctionFilterUtils.createUrlFriendlyFilterObject(newFilter);
  }

  protected transformStateIntoQueryParams(newValues: Partial<FilterState>) {
    const currentStore = this.store.getValue();

    return {
      ...this.createUrlFilter(newValues.filter || currentStore.filter),
    };
  }

  // limit has to be greater than 0, offset can be 0 or greater
  protected isPaginationValid({ limit, offset }: IAuctionFilter) {
    return limit && offset !== undefined;
  }

  public areFiltersDifferent(filter1: IAuctionFilter, filter2: IAuctionFilter) {
    const f1WithoutPag = this.buyerAuctionSearchService.removeLimitAndOffset(filter1);
    const f2WithoutPag = this.buyerAuctionSearchService.removeLimitAndOffset(filter2);
    return !this.buyerAuctionSearchService.areAuctionFiltersEqual(f1WithoutPag, f2WithoutPag);
  }

  public updatePowerUnit(powerUnit: EPowerUnit) {
    AuctionFilterUtils.setPowerPreferenceInLocalStorage(powerUnit);
    this.store.update(state => ({ ...state, powerUnit }));
  }

  public updateSearchResultsCount(searchResultsCount: number | null) {
    this.searchResultsCount.set(searchResultsCount);
  }

  public updatePagination({ limit, offset }: Pick<IAuctionFilter, 'limit' | 'offset'>) {
    const currentFilter = this.store.getValue().filter;
    const safeLimit = Math.min(limit ?? currentFilter.limit, MAX_PAGE_SIZE);
    this.updateFilter({ ...currentFilter, limit: safeLimit, offset });
  }

  public updateFilter(filter: IAuctionFilter) {
    this.cleanLocationFilter(filter);
    this.navigateWithQueryParams({ filter } as Partial<T>);
  }

  public resetFilter() {
    this.updateFilter(this.getResetState());
  }

  public updateQuickSearch(searchQuery: string) {
    this.updateFilter({
      ...this.store.getValue().filter,
      search: searchQuery,
    });
  }

  private cleanLocationFilter(filter: IAuctionFilter) {
    const isDistanceRadiusEntered = !!filter.distance?.radius;
    if (isDistanceRadiusEntered) {
      filter.includeCountries = [];
      this.resetZipCodeFilter(filter);
      return;
    }

    const isCountrySelected = filter.includeCountries?.length > 0;
    if (isCountrySelected) {
      this.resetDistanceRadiusFilter(filter);

      const isMoreThanOneCountrySelected = filter.includeCountries?.length > 1;
      if (isMoreThanOneCountrySelected) {
        this.resetZipCodeFilter(filter);
      }
    } else {
      this.resetZipCodeFilter(filter);
    }
  }

  private resetZipCodeFilter(filter: IAuctionFilter) {
    if (filter.locationZipCodeQuery) {
      filter.locationZipCodeQuery = '';
    }
  }

  private resetDistanceRadiusFilter(filter: IAuctionFilter) {
    if (filter.distance && filter.distance.radius) {
      filter.distance.radius = undefined;
    }
  }
}
