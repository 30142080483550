// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { E2EModule } from '@cosCoreModules/e2e/e2e.module';
import { IAppEnvironment } from './IAppEnvironment';
import { commonEnvironmentSettings } from './common-environment-settings';

export const environment: IAppEnvironment = {
  ...commonEnvironmentSettings,
  label: 'development',

  production: false,

  disableLogging: false,
  disableSentry: true,

  backendUrl: `https://dev.caronsale.de/api/v1`,

  idpUserPoolId: 'eu-central-1_4selCrLrH',
  idpUserPoolWebClientId: '2sdplsf16qt8qpao5a7his0r7e',
  idpCookieDomain: 'caronsale.de',
  idpCookieSecure: true,
  idpDisableCookieStorage: true,

  captchaSiteKey: `6Lcj65sUAAAAAFajuBznGFPGfNUyBxrAk7x2VAU8`,

  pickupCaptchaSiteKey: '6LdvxwwbAAAAAJgtGtSAYWaxRzto4J4A5VRNDs0E',

  pdfTronSecretKey: 'Castle Tech GmbH(caronsale.de):OEM:CarOnSale::B+:AMS(20210228):E5A504DD0437280AB360B13AC9A2537B60612FFDA776DD159DCF05634C70488054F2B6F5C7',

  googleAnalyticsId: 'G-F9X3R5T5YZ',

  googleTagManagerId: 'GTM-PCJ3QLKF',

  domain: 'localhost', // 'www.caronsale.com',

  notificationCenterSocketUrl: 'https://cos-notification-service-dev.herokuapp.com/',

  vehicleIntakeJotFormUrl: 'https://form.jotform.com/212912177856058',

  cargoUrl: 'https://cargo-dev.caronsale.de?utm_source=cos-marketplace&utm_medium=banner',
  cargoApiUrl: 'https://cargo-api-dev.caronsale.de',

  envModules: [E2EModule],

  salesforceUrl: 'https://caronsale--dev.sandbox.lightning.force.com',

  productFruitsWorkspaceCode: 'yyaKZrOYPUzpKXMz',

  zohoWorkspace: '20087941613',

  VATFactor: 1.19,

  billieStaticUrl: 'https://static-paella-sandbox.billie.io',

  amplitudeApiKey: '7f22e930f7c8a5d59609aab77800c877',
  amplitudeExperimentDeploymentKey: 'client-vweZe5xlMKV3Nfw30lluaR2zElGy6BRx',
  amplitudeSessionReplayEnabled: false,
  amplitudeProxyUrl: 'https://dev.caronsale.de/amplitude',

  useProdCloudinaryAccount: false,
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
