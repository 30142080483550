import { BehaviorSubject, catchError, distinctUntilChanged, filter, finalize, from, map, Observable, of, switchMap, tap } from 'rxjs';
import { Experiment, ExperimentClient, Variant } from '@amplitude/experiment-js-client';
import { Types } from '@amplitude/analytics-browser';
import { Ampli, ampli, EventOptions, IdentifyProperties, LoadOptions } from './ampli';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { IAppEnvironment } from '@cosCoreEnvironments/IAppEnvironment';

/**
 * Default value used by amplitude for feature flags
 */
const FEATURE_FLAG_VALUE = 'on';
export type FeatureFlagEnabled = typeof FEATURE_FLAG_VALUE;

/**
 * Feature flag type
 * @param V The value of the feature flag. The default is "on"
 * @param P The payload of the feature flag. Any valid JSON value
 */
export type FeatureFlag<V extends string = FeatureFlagEnabled, P = undefined> = {
  value: V;
  payload?: P;
};

export type AmplitudeTrackingEvents = { [K in keyof Omit<Ampli, 'client' | 'flush' | 'identify' | 'isLoaded' | 'load' | 'track'>]: Ampli[K] };
export type AmplitudeTrackingEventKeys = keyof AmplitudeTrackingEvents;

// type which evaluates to Y if T and U are equal, and N otherwise.
type IfEqualElse<T, U, Y = unknown, N = never> = (<G>() => G extends T ? 1 : 2) extends <G>() => G extends U ? 1 : 2 ? Y : N;

export type AmplitudeTrackingEventPayload<T extends AmplitudeTrackingEventKeys> = IfEqualElse<
  Parameters<Ampli[T]>[0],
  EventOptions,
  never,
  Parameters<Ampli[T]>[0]
>;

const SESSION_REPLAY_HOST_WHITELIST: string[] = ['app.caronsale.de'];

export class Amplitude {
  /**
   * Load options for amplitude
   */
  private readonly AMPLI_LOAD_CONFIG: (userId: string) => LoadOptions = (userId: string) => ({
    client: {
      apiKey: this.environment.amplitudeApiKey,
      configuration: {
        serverZone: 'EU',
        userId,
        logLevel: Types.LogLevel.None,
        serverUrl: this.environment.amplitudeProxyUrl,
        defaultTracking: {
          attribution: false,
          fileDownloads: false,
          formInteractions: false,
          pageViews: {
            // angular routing history replaces are not being tracked
            trackHistoryChanges: 'all',
          },
          sessions: true,
        },
      },
    },
  });

  public static instance: Amplitude;

  private featureFlagsLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private featureFlagsLoaded$: Observable<boolean> = this.featureFlagsLoadedSubject.pipe(filter(Boolean));

  private experiment: ExperimentClient;

  public constructor(
    userUuid: string | null,
    private environment: IAppEnvironment,
  ) {
    if (Amplitude.instance) {
      return Amplitude.instance;
    }

    Amplitude.instance = this;

    /**
     * TODO: Remove this when the amplitude plugin supports proxying requests
     * Monkey patch fetch to intercept session replay requests
     * Since we are using a proxy for amplitude, we need to change the destination URL
     * This is a workaround until the amplitude plugin supports proxying requests
     */
    this.monkeyPatchFetchToInterceptSessionReplayRequests();

    // If there is no authenticated user, we don't initialize amplitude analytics, only the experiment client
    // We do this because we are running a MTU based plan and we don't want to track unauthenticated users which exceeds the quota
    if (userUuid) {
      this.initAnalytics(this.AMPLI_LOAD_CONFIG(userUuid)).subscribe();
    }

    this.initExperiment(userUuid);
  }

  /**
   * Check if the given feature is enabled
   */
  public isOn(feature: string): Observable<boolean> {
    return this.featureFlagsLoaded$.pipe(
      map(() => this.experiment.variant(feature).value === FEATURE_FLAG_VALUE),
      catchError(e => {
        console.error('Error while checking feature isOn', e);
        return of(false);
      }),
      distinctUntilChanged(),
    );
  }

  /**
   * Check if the given feature is disabled
   */
  public isOff(feature: string): Observable<boolean> {
    return this.featureFlagsLoaded$.pipe(
      map(() => this.experiment.variant(feature).value !== FEATURE_FLAG_VALUE),
      catchError(e => {
        console.error('Error while checking feature isOff', e);
        return of(true);
      }),
      distinctUntilChanged(),
    );
  }

  /**
   * Get feature return value and payload with fallback when is disabled
   */
  public getFeatureFlag(feature: string, fallbackValue: FeatureFlag<string, any>): Observable<FeatureFlag<string, any>> {
    return this.featureFlagsLoaded$.pipe(
      map(() => {
        const featureFlag: Variant = this.experiment.variant(feature);
        return this.experiment.variant(feature).value
          ? {
              value: featureFlag.value,
              payload: featureFlag.payload,
            }
          : fallbackValue;
      }),
      catchError(e => {
        console.error('Error while getting feature flag value', e);
        return of(fallbackValue);
      }),
      distinctUntilChanged((prev, curr) => prev.value === curr.value && prev.payload === curr.payload),
    );
  }

  /**
   * Identify the user for analytics and experiment
   * If the user was not identified before, therefore analytics were not initialized, we initialize them first and then identify the user
   */
  public identifyUserByUuid(userUuid: string): void {
    // We reset the feature flags loaded state to false so we don't emit values from the previous user
    this.featureFlagsLoadedSubject.next(false);

    this.initAnalytics(this.AMPLI_LOAD_CONFIG(userUuid))
      .pipe(
        switchMap(() => from(ampli.identify(userUuid).promise)),
        catchError(e => {
          console.error('Error while identifying user', e);
          return of(null);
        }),
      )
      .subscribe(() => {
        this.experiment.setUser({
          user_id: userUuid,
        });
        this.refreshFeatureFlags();
      });
  }

  /**
   * Set the current identified user properties
   * Properties are overwritten if they already exist here and in Amplitude
   * So the last values set will be the true ones
   */
  public setUserProperties(userUuid: string, identifyProperties: IdentifyProperties): void {
    if (!ampli.isLoaded) {
      console.error(`ERROR: Ampli was not yet initialized. User properties won't be set.`);
      return;
    }

    from(ampli.identify(userUuid, identifyProperties).promise)
      .pipe(
        catchError(e => {
          console.error('Error while identifying user', e);
          return of(null);
        }),
      )
      .subscribe();
  }

  /**
   * Force a feature flags refresh
   */
  public refreshFeatureFlags(): void {
    if (!this.experiment) {
      return;
    }

    from(this.experiment.fetch())
      .pipe(
        catchError(e => {
          console.error('Error while refreshing flags', e);
          return of(null);
        }),
        finalize(() => this.featureFlagsLoadedSubject.next(true)),
      )
      .subscribe();
  }

  /**
   * Get the tracking events object to use in the application
   */
  public getTrackingEvents(): AmplitudeTrackingEvents {
    if (!ampli.isLoaded) {
      console.error('ERROR: Ampli was not yet initialized. This event will not be tracked.');
    }

    return ampli;
  }

  /**
   * get the id of the replay session
   */
  public getSessionReplayUrl(): string {
    if (!ampli.isLoaded || !ampli.client) {
      return null;
    }
    const sessionId = String(ampli.client.getSessionId());
    return `https://app.eu.amplitude.com/analytics/caronsale/session-replay?filters=[{"type":"user-property","value":{"property":{"group_type":"User","type":"user","value":"session_id"},"operator":"is","values":["${sessionId}"]}}]&dateRangeParams={"range":[90,0]}`;
  }

  /**
   * Initialize the experiment client
   * When no userUuid is provided, the experiment will run anonymously until the user is logged in
   */
  private initExperiment(userUuid: string): void {
    try {
      this.experiment = Experiment.initialize(this.environment.amplitudeExperimentDeploymentKey, {
        pollOnStart: false,
        fetchOnStart: false,
        serverZone: 'EU',
        serverUrl: this.environment.amplitudeProxyUrl,
        flagsServerUrl: this.environment.amplitudeProxyUrl,
      });

      from(this.experiment.start())
        .pipe(
          tap(() => {
            // if we have an auth session we identify the user, otherwise we run the experiment anonymously until the user is logged in
            if (userUuid) {
              this.experiment.setUser({
                user_id: userUuid,
              });
            }
            this.refreshFeatureFlags();
          }),
          catchError(e => {
            console.error('Error while initializing Amplitude experiment', e);
            return of(null);
          }),
          finalize(() => this.featureFlagsLoadedSubject.next(true)),
        )
        .subscribe();
    } catch (error) {
      console.error('Error while initializing Amplitude experiment', error);
      this.experiment = null;
    }
  }

  /**
   * Initialize the amplitude analytics if not already loaded
   */
  private initAnalytics(ampliLoadConfig: LoadOptions): Observable<void> {
    if (ampli.isLoaded) {
      return of(null);
    }

    return from(ampli.load(ampliLoadConfig).promise).pipe(
      tap(() => {
        if (!this.environment.amplitudeSessionReplayEnabled || window['Cypress'] || !SESSION_REPLAY_HOST_WHITELIST.includes(window.location.host)) {
          return;
        }

        const amplitude = ampli.client;
        if (!amplitude || !ampli.isLoaded) {
          throw new Error('Amplitude client is not initialized, cannot add session replay plugin');
        }
        const sessionReplayTracking = sessionReplayPlugin({
          sampleRate: 1,
        });
        amplitude.add(sessionReplayTracking);
      }),
      catchError(e => {
        console.error('Error while initializing Amplitude analytics', e);
        return of(null);
      }),
    );
  }

  private monkeyPatchFetchToInterceptSessionReplayRequests(): void {
    if (!this.environment.amplitudeProxyUrl) {
      return;
    }

    const { fetch: originalFetch } = window;
    const SESSION_REPLAY_URL = 'https://api-sr.eu.amplitude.com/sessions/v2';
    const SESSION_REPLAY_CONFIG_URL = 'https://sr-client-cfg.eu.amplitude.com';

    try {
      window.fetch = async (resource, config) => {
        try {
          const requestUrl = resource.toString();
          const isSessionReplayTrackRequest = requestUrl.startsWith(SESSION_REPLAY_URL);
          const isSessionReplayConfigRequest = requestUrl.startsWith(SESSION_REPLAY_CONFIG_URL);

          if (!isSessionReplayTrackRequest && !isSessionReplayConfigRequest) {
            return originalFetch(resource, config);
          }

          const urlToReplace = isSessionReplayTrackRequest ? SESSION_REPLAY_URL : SESSION_REPLAY_CONFIG_URL;
          const newResource = requestUrl.replace(urlToReplace, this.environment.amplitudeProxyUrl);

          return originalFetch(newResource, config);
        } catch (error) {
          console.error('Error while intercepting session replay request', error);
          return originalFetch(resource, config);
        }
      };
    } catch (error) {
      window.fetch = originalFetch;
      console.error('Error while monkey patching fetch for session replay requests', error);
    }
  }
}
