export * from './generated.types';

import {
  IHasInspectionAppointmentCountsByState,
  IHasInspectionRequestCountsByState,
  InspectionAppointmentForSellerDto,
  EVehicleInspectionRequestState,
  IGenericFilter,
  EInspectionAppointmentStatus,
} from './generated.types';

export { EVehicleInspectionRequestState as EInspectionRequestState };

// export type IInspectionRequestFilter = InspectionRequestFilterDtoGenerated; // missing on the backend API
export type IInspectionRequestCountByState = IHasInspectionRequestCountsByState['countsByState'];

export type IInspectionAppointment = InspectionAppointmentForSellerDto;
// export type IInspectionAppointmentFilter = InspectionAppointmentFilterDtoGenerated; // missing on the backend API
export type IInspectionAppointmentCountByState = IHasInspectionAppointmentCountsByState['countsByState'];

// TODO: remove replicated types when they're properly exported by the backend API
export { IVehicleInspectionRequestFilter as IInspectionRequestFilter } from '@caronsale/cos-models';
export type IInspectionAppointmentFilter = {
  offset?: number;
  limit?: number;
  sortBy?: IGenericFilter[];
  uuids?: string[];
  inspectorUuid?: string;
  sellerUuid?: string;
  sellerUuids?: string[];
  states?: EInspectionAppointmentStatus[];
};
